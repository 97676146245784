import Hero from '../components/Hero';
import Calculator from '../components/tool/Calculator/Calculator';

const CalculatorPage = (): JSX.Element => {
  return (
    <div id="calculator-page">
      
      <Calculator />
    </div>
  );
};

export default CalculatorPage;
