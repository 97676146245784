// src/pages/ROICalculatorPage.js
import React from 'react';
import ROICalculator from '../components/tool/ROICalculator/ROICalculator';


const ROICalculatorPage = () => {
  return (
    <div>
      <ROICalculator />
    </div>
  );
};

export default ROICalculatorPage;
